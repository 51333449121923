import { LocaleContext, LocalizedLink } from 'gatsby-theme-i18n';
import * as React from 'react';

import { TOP_OF_PAGE_ID } from '../../const/app.const';
import { goBackLinkContainer } from './go-back-link.module.css';

export const GoBackLink: React.FunctionComponent = () => {
    const locale = React.useContext(LocaleContext);
    const backLabel = locale === 'en' ? 'Homepage' : 'Startseite';
    const toTheTopLabel = locale === 'en' ? 'Top' : 'Nach oben';

    return (
        <div
            className={goBackLinkContainer}
        >
            <LocalizedLink
                to="/"
                language={locale}
            >
                {backLabel}
            </LocalizedLink>
            <a href={`#${TOP_OF_PAGE_ID}`}>
                <span aria-hidden="true">&uarr;&nbsp;</span>{toTheTopLabel}
            </a>
        </div>
    );
};
